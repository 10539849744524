import * as React from "react"
import { HeadFC } from "gatsby"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import Nav from "../components/Nav"
import { GlobalHead } from "../components/GlobalHead"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl-v4"
import languageFromLocale from "../helper/language-helper"
import "../styles.scss"

const InfoPage = ({ data }) => {
  const intl = useIntl()
  const content = data
    .allContentfulInfoPage.nodes.find(n => n.node_locale === languageFromLocale(intl.locale))
    .text.internal.content
  return (
    <div>
      <Nav />
      <div className={'info'}>
        <div className={'info__content'}>
          <ReactMarkdown
            children={content}
            className={'formatted-text'}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          />
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    allContentfulInfoPage {
      nodes {
        node_locale
        text {
          internal {
            content
          }
        }
      }
    }
  }
`;

export default InfoPage

export function Head() {
  return (
    <GlobalHead title="Info" />
  )
}
